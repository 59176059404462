import type { NextPage } from "next";
import Head from "next/head";
import { ProjectLayout } from "components/Layout";
import { WalletProjectPage } from "components/ProjectPage";
import { getStaticPaths, getStaticProps } from "modules/WLProject";
import { ProjectMenu, WalletSubmenu } from "components/ProjectMenu";
import { getCustomStyle } from "utils/getCustomStyle";

type Props = {
  projectName: string;
  projectSlug: string;
  projectMetadata: any;
  projectDescription: string;
  projectImageUrl: string;
};

const Project: NextPage<Props> = (props) => {
  return (
    <ProjectLayout
      projectName={props.projectName}
      projectSlug={props.projectSlug}
      projectDescription={props.projectDescription}
      projectImageUrl={props.projectImageUrl}
      menuComponent={<ProjectMenu submenu={<WalletSubmenu />} />}
      customStyle={getCustomStyle(props.projectSlug)}
    >
      <WalletProjectPage
        projectName={props.projectName}
        projectSlug={props.projectSlug}
      />
    </ProjectLayout>
  );
};

export { getStaticPaths, getStaticProps };

export default Project;
